import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionRequiredAuthError,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ServiceWorkerModule } from '@angular/service-worker';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
const config: SocketIoConfig = { url:  environment.api, options: {} };

// export function loggerCallback(logLevel: LogLevel, message: string) {
//   // console.log(message);
// }

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority:
        'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
      redirectUri: environment.redirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        // loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, [environment.scopes]);
  protectedResourceMap.set(environment.api, [environment.scopes]);
  protectedResourceMap.set('/api/', [
    'b19241ac-6879-4420-9202-f68270aeaa85/.default',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.scopes],
    },
  };
}

function initializeAppFactory(
  httpClient: HttpClient,
  authService: AuthService,
  msalService: MsalService
): () => Observable<any> {
  return () =>
    msalService.acquireTokenSilent({ scopes: [environment.scopes] }).pipe(
      switchMap(() => {
        const activeUserId = authService.getActiveAccount()?.idTokenClaims?.oid;
        return activeUserId
          ? authService.getRole(activeUserId).pipe(catchError(() => of('')))
          : of('');
      }),
      tap((role: string) => {
        authService.userRole = role;
      }),
      catchError((error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalService.acquireTokenRedirect({
            scopes: [environment.scopes],
          });
        }
        return of('');
      })
    );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [HttpClient, AuthService, MsalService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    DialogService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
