import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { ROLES } from '@shared/constants/roles';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginDisplay = false;
  private _userRole = '';
  private _userProfile: any;
  constructor(
    private router: Router,
    private authService: MsalService,
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }


  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/login',
      });
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.router.navigate(['/experience-feed']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getActiveAccount() {
    return this.authService.instance.getActiveAccount();
  }

    getRole(userId: string) {
      return this.http
        .get<any>(`${environment.api}/role/${userId}`)
        .pipe(map((res: any) => res.data));
    }

    canAccessAdminSettings() {
      return this.userRole === ROLES.ADMIN || this.userRole === ROLES.MANAGER;
    }

    canAccessMyProjects() {
      return this.userRole !== ROLES.GUEST;
    }

    canAccessKeywords() {
      return this.userRole === ROLES.ADMIN;
    }

    canAccessResponsibility() {
      return this.userRole === ROLES.ADMIN;
    }

    canAccessWellDetails() {
      return this.userRole === ROLES.ADMIN;
    }

    isAdmin() {
      return this.userRole === ROLES.ADMIN;
    }

    isManager() {
      return this.userRole === ROLES.MANAGER;
    }

    isCurrentUser(userId: string): boolean {
      return userId === this.getActiveAccount()?.localAccountId;
    }

  get userRole() {
      return this._userRole;
    }

  set userRole(role: string) {
      this._userRole = role;
    }

  get userProfile() {
      return this._userProfile;
    }

  set userProfile(userProfile: any) {
      this._userProfile = userProfile;
    }
  }
